import {
    Box, SideMenu, SideMenuItem, InformationDialog,
} from '@encompaas/common/components';
import PageWithAppBar from '../components/PageWithAppBar';
import { useUser } from '@encompaas/common/hooks';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@encompaas/common/store';
import { AnalyticsUser } from '@encompaas/common';
import { useNavigate } from 'react-router-dom';
import {
    setSelectedSideMenuItemValue,
    setSideMenuCollapsed,
} from '@encompaas/common/store';
import { setDashboardData, setDashboardConfig, getLogiEndpoint, getInventoryEndpoint, _menuItems, updateDashboard } from '../utils/AnalyticsHelper';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useGetAnalyticsUserQuery } from "@encompaas/common/services";

const REFRESH_INTERVAL = 120000;

const HomePage = () => {
    const user = useUser(true) as AnalyticsUser;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isScriptLoading, setScriptLoading] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const _errorTitle = "We've encountered an error.";
    const [errorValue, setErrorValue] = useState("");
    const currentComponentId = useRef(0);
    const { data: analyticsUserData, refetch: refetchAnalyticsUser } = useGetAnalyticsUserQuery(user);

    ///// Functional methods
    const {
        sideMenuCollapsed,
    } = useAppSelector((state) => state.application?.ui ?? {});

    const [selectedSideMenuItem, setSelectedSideMenuItem] = useState<
        SideMenuItem | undefined
    >(_menuItems?.[0]?.items?.[0]);

    const [menuItems, setMenuItems] = useState(_menuItems);

    const handleCollapse = (value: boolean) => {
        dispatch(setSideMenuCollapsed(value));
    };

    const getCurrentToken = useCallback(() => {
        return analyticsUserData?.access_token || user.access_token;
    }, [analyticsUserData, user]);

    const handleSelect = useCallback((item?: SideMenuItem) => {
        dispatch(
            setSelectedSideMenuItemValue(item?.value)
        );
        setSelectedSideMenuItem(item);

        //Render a new dashboard, if the item has a key.
        if (item?.key === "0") return;

        try {
            const userToken = getCurrentToken();

            /////////////// COMPONENT EMBEDDING //////////////////////
            // @ts-ignore
            const embedManagerPromise = window.initComposerEmbedManager({ initialToken: atob(userToken) });

            //Remove the old component.
            const removeEmbedComponent = (embedManager: any) => {
                // @ts-ignore
                return embedManager.removeComponent(currentComponentId.current);
            };

            // @ts-ignore
            const createEmbedComponent = (embedManager: any, config: any, containerElementId = 'dashboard-widget') => {
                // @ts-ignore
                return embedManager.createComponent("dashboard", config).then((component: any) => {
                    component.render(document.getElementById(containerElementId), { width: "100%", height: "100%" });
                    currentComponentId.current = component.componentInstanceId;
                    component.addEventListener("composer-dashboard-failed", () => {
                        //Raise an error on the ui.
                        setErrorValue("Failed to load the Dashboard. You may not have permissions to view the dashboard.");
                        setErrorDialogOpen(true);
                    });
                });
            };

            // @ts-ignore
            embedManagerPromise.then((embedManager: any) => {
                removeEmbedComponent(embedManager);
                createEmbedComponent(embedManager, updateDashboard(item?.key), 'dashboard-widget');
            }).catch((error: string) => {
                //Raise an error on the ui.
                setErrorValue(error);
                setErrorDialogOpen(true);
            });
        } catch (ex) {
            setErrorValue("Failed to initialise the Analytics system. This could be due to a system outage, please confirm with your Administrators that the Analytics system is functioning as expected.");
            setErrorDialogOpen(true);
        }
    }, [dispatch, getCurrentToken]);

    const loadScripts = useCallback(async () => {
        if (document.getElementById("composer-embed-manager")) return;

        setScriptLoading(true);

        // function to append scripts to document head
        const loadScript = (src: string, id?: string) => {
            return new Promise<void>((resolve, reject) => {
                const script = document.createElement("script");
                script.src = src;
                if (id) {
                    script.id = id;
                    script.setAttribute("data-name", id);
                }
                script.onload = () => resolve();
                script.onerror = reject;
                document.head.appendChild(script);
            });
        };

        try {
            // Try add scripts to dom with id if required
            await loadScript(`${getLogiEndpoint()}embed/embed.js`, "composer-embed-manager");
            await loadScript(`${getLogiEndpoint()}api/branding/customJs.js`);

            window.addEventListener("load", async () => {
                // @ts-ignore
                window.replaceIcons();
            });

            setScriptLoading(false);
        } catch (error) {
            console.error("Failed to load scripts:", error);
            setErrorValue("Failed to load necessary scripts. Please refresh the page.");
            setErrorDialogOpen(true);
            setScriptLoading(false);
        }
    }, []);

    const refreshToken = useCallback(async () => {
        try {
            await refetchAnalyticsUser();
        } catch (error) {
            console.error("Failed to refresh token:", error);
            setErrorValue("Failed to refresh authentication. Please try logging in again.");
            setErrorDialogOpen(true);
        }
    }, [refetchAnalyticsUser]);

    useEffect(() => {
        loadScripts();
    }, [loadScripts]);

    useEffect(() => {
        const intervalId = setInterval(refreshToken, REFRESH_INTERVAL);
        return () => clearInterval(intervalId);
    }, [refreshToken]);

    /*
    This hook requires the users data to be populated, if the user doesnt have a token it should throw an error.
    */
    useEffect(() => {
        /// If the data isnt available, skip render.
        if (user?.access_token && !isScriptLoading) {
            const userToken = getCurrentToken();

            //////////////////////////// EMBED MANAGER ///////////////////////////
            // @ts-ignore
            const embedManagerPromise = window.initComposerEmbedManager({ initialToken: atob(userToken) });

            embedManagerPromise.then((embedManager: any) => {
                ///////////////////// INVENTORY ///////////////////////////////////
                const auth_token = `Bearer ${atob(userToken)}`;

                fetch(getInventoryEndpoint(), {
                    method: "GET",
                    headers: {
                        "accept": "application/vnd.composer.v3+json,application/vnd.composer+json",
                        "authorization": auth_token,
                    }
                }).then(response => response.json())
                    .then(data => setDashboardData(data))
                    .catch((error) => {
                        setErrorValue(String(error));
                        setErrorDialogOpen(true);
                    })
                    .finally(() => {
                        setMenuItems(_menuItems);
                        if (_menuItems?.[0]?.items?.[0].key !== "0") {
                            setSelectedSideMenuItem(_menuItems?.[0]?.items?.[0]);
                            embedManager.createComponent("dashboard", setDashboardConfig(_menuItems?.[0]?.items?.[0].key))
                                .then((component: any) => {
                                    component.render(document.getElementById('dashboard-widget'), { width: "100%", height: "100%" });
                                    currentComponentId.current = component.componentInstanceId;
                                    component.addEventListener("composer-dashboard-failed", () => {
                                        //Raise an error on the ui.
                                        setErrorValue("Failed to load the Dashboard. You may not have permissions to view the dashboard.");
                                        setErrorDialogOpen(true);
                                    });
                                })
                                .catch((error: string) => {
                                    setErrorValue(error);
                                    setErrorDialogOpen(true);
                                });
                        }

                        // Create the script header object and render on load for the CustomCSS
                        // Do this last to ensure we get these applied to the UI
                        const cssId = 'customCss';
                        if (!document.getElementById(cssId)) {
                            const link = document.createElement('link');
                            link.id = cssId;
                            link.rel = 'stylesheet';
                            link.type = 'text/css';
                            link.href = `${getLogiEndpoint()}api/branding/customCss.css`;
                            link.media = 'all';
                            document.head.appendChild(link);
                        }
                    });
            }).catch((error: string) => {
                //Raise an error on the ui.
                setErrorValue(error);
                setErrorDialogOpen(true);
            });
        }
    }, [user, isScriptLoading, getCurrentToken]);

    return (
        <PageWithAppBar data-testid={TEST_CONSTANTS.HOME_PAGE}>
            <Box direction='row' background='none' gap='medium'>
                <SideMenu
                    collapsed={sideMenuCollapsed}
                    selected={selectedSideMenuItem}
                    collapsedSize='xlarge'
                    onSelect={handleSelect}
                    onCollapse={handleCollapse}
                    items={menuItems}
                    blur
                />
                <Box id="zd-root">
                    <div
                        className="dashboard-widget ecs-box ecs-background-light ecs-rounded-top ecs-box-column ecs-padding-none ecs-gap-none ecs-border-none ecs-border-color-dark ecs-box-blur"
                        id="dashboard-widget"
                        style={{ height: '-webkit-fill-available', width: '-webkit-fill-available' }}
                    >
                    </div>
                </Box>
            </Box>
            <InformationDialog
                open={errorDialogOpen}
                onClose={() => setErrorDialogOpen(false)}
                title={_errorTitle}
                variant={'error'}
                information={errorValue}
            />
        </PageWithAppBar>
    );
};

export default HomePage;